import React from "react"
import { useEffect } from "react"
import styles from "./iletisim.module.scss"
import Menubar from "../components/menubar/menubarEng"
import GMap from "../components/gMap/gMap"
import Footer from "../components/footer/footerEng"
import ContactTop from "../img/contactTop.jpg"
import ContactBottom from "../img/contactBottom2.jpg"
import LogoStanding from "../img/logo-standalone.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"
import { faMapMarker } from "@fortawesome/free-solid-svg-icons"
import { faAddressCard } from "@fortawesome/free-regular-svg-icons"
import { render } from "react-dom"

const Contact = () => {
  useEffect(() => {
    document.addEventListener("load", GMap => {
      render(GMap)
    })
  })
  return (
    <div className={styles.contactContainer}>
      <div className={styles.leftE}></div>
      <div className={styles.centerSite}>
        <Menubar />
        <div className={styles.contactTopContainer}>
          <img src={ContactTop} alt="oyma" className={styles.imageTopContact} />
          <div className={styles.verticalLineWhiteContact}>
            <div className={styles.socialMediaIcons}>
              <a
                href="https://www.facebook.com/atiaahsap"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="1x"
                  className={styles.ffIcon}
                />
              </a>
              <br />
              <a
                href="https://www.instagram.com/atiaahsap/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="1x"
                  className={styles.igIcon}
                />
              </a>
            </div>
          </div>
          <span className={styles.topSpanContact}>GET IN TOUCH</span>
        </div>
        <div className={styles.contactMiddleContainer}>
          <div className={styles.mapContact}>
            <GMap width="100%" height="100%" />
          </div>
          <div className={styles.mapAddress}>
            <div className={styles.mapAddressContainer}>
              <FontAwesomeIcon
                icon={faAddressCard}
                className={styles.contactIcons}
              />
              <p className={styles.contactTexts}>
                E-MAIL <br />{" "}
                <a
                  href="mailto:atia@atia.com.tr"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "#ffffff" }}
                >
                  atia@atia.com.tr
                </a>
              </p>
            </div>
            <div className={styles.mapAddressContainer}>
              <FontAwesomeIcon
                icon={faMapMarker}
                className={styles.contactIcons}
              />
              <p className={styles.contactTexts}>
                FACTORY <br /> Hasanoğlan Bahçelievler Nbh. <br />
                651. Ave. No: 50
                <br />
                Elmadağ / ANKARA
                <br />
                Phone: +90 312 227 0600
                <br />
              </p>
            </div>
            <div className={styles.mapAddressContainer}>
              <FontAwesomeIcon
                icon={faMapMarker}
                className={styles.contactIcons}
              />
              <p className={styles.contactTexts}>
                OFFICE / SHOWROOM <br /> Önder Nbh. Ereğli Ave. No: 65
                <br />
                Altındağ / ANKARA
                <br />
                Phone: +90 312 348 6307
                <br />
                Fax: +90 312 350 2187
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.standingA}>
            <img src={LogoStanding} alt="standing A" />
          </div>
          <img
            src={ContactBottom}
            alt="Contact bottom"
            className={styles.contactBackground}
          />
        </div>
        <Footer />
      </div>
      <div className={styles.rightE}></div>
    </div>
  )
}

export default Contact
