import React from "react"

export default props => (
  <div style={{ width: "100%", height: "100%" }}>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7118.1123034228585!2d32.9109985873252!3d39.956346561793524!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd20f8d5b9fc0e322!2zQXRpYSBBaMWfYXAgVGFzYXLEsW0gxLDDpyBNaW1hcmzEsWs!5e0!3m2!1sen!2str!4v1578145017387!5m2!1sen!2str"
      width={props.width}
      height={props.height}
      frameBorder="0"
      style={{ border: "0" }}
      allowFullScreen=""
      title="atia"
    ></iframe>
  </div>
)
